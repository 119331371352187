import { useEffect } from 'react'

/**
 * Custom Effect to close menu if click outside
 */
const useOnClickOutside = (ref: React.RefObject<HTMLElement>, setStateCallback: (arg0: boolean) => void) => {
  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setStateCallback(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export default useOnClickOutside
