import * as React from 'react'
import css from './PriceOverride.scss'
import { toFixedDec } from '../../../Utils/priceFormatter'
import StopWatch from './StopWatch'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import useOnClickOutside from '../../../Utils/hooks/useOnClickOutside'

export interface IPriceOverrideProps {
  productOverridePrice: any
}

const PriceOverride = ({ productOverridePrice }: IPriceOverrideProps) => {
  const [displaySpecialBuyTooltip, setDisplaySpecialBuyTooltip] = useState(false)

  const closeDetailsRef: React.RefObject<HTMLDivElement> = React.useRef(null)
  useOnClickOutside(closeDetailsRef, () => setDisplaySpecialBuyTooltip(false))

  return (
    <>
      <div
        onClick={() => setDisplaySpecialBuyTooltip(true)}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <div>
          <StopWatch />
        </div>
        <div className={css.override}>SPECIAL BUY: {toFixedDec(productOverridePrice)}</div>
      </div>
      {displaySpecialBuyTooltip && (
        <div
          className={`${css.storeDetailsContainer} ${css.storeDetailsModal} ${
            displaySpecialBuyTooltip ? css.display : css.hide
          }`}
          ref={closeDetailsRef}
        >
          <div className={css.storeDetailsCloseIcon} onClick={() => setDisplaySpecialBuyTooltip(false)}>
            <FontAwesomeIcon icon={faTimes} size="xs" />
          </div>
          **Special Buy Pricing expires at midnight today. Complete your order in Checkout to take advantage of this
          pricing offer.
        </div>
      )}
    </>
  )
}

export default PriceOverride
