import React from 'react'

const StopWatch = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      zoomAndPan="magnify"
      viewBox="0 0 810 809.999993"
      height="34"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <g />
        <clipPath id="3ac51e09ff">
          <path d="M 67.046875 313 L 271 313 L 271 352 L 67.046875 352 Z M 67.046875 313 " clip-rule="nonzero" />
        </clipPath>
      </defs>
      <g clip-path="url(#3ac51e09ff)">
        <path
          fill="#ff3131"
          d="M 270.289062 332.355469 C 270.289062 321.6875 261.613281 313.011719 250.945312 313.011719 L 86.40625 313.011719 C 75.734375 313.011719 67.058594 321.6875 67.058594 332.355469 C 67.058594 343.007812 75.734375 351.683594 86.40625 351.683594 L 250.945312 351.683594 C 261.613281 351.683594 270.289062 343.007812 270.289062 332.355469 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill="#ff3131"
        d="M 270.289062 515.84375 C 270.289062 505.175781 261.613281 496.5 250.945312 496.5 L 134.878906 496.5 C 124.226562 496.5 115.550781 505.175781 115.550781 515.84375 C 115.550781 526.515625 124.226562 535.191406 134.878906 535.191406 L 250.945312 535.191406 C 261.613281 535.191406 270.289062 526.515625 270.289062 515.84375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#ff3131"
        d="M 250.945312 404.878906 L 175.054688 404.878906 C 164.382812 404.878906 155.710938 413.554688 155.710938 424.222656 C 155.710938 434.894531 164.382812 443.566406 175.054688 443.566406 L 250.945312 443.566406 C 260.824219 443.566406 268.988281 436.132812 270.136719 426.554688 L 270.15625 424.316406 C 270.175781 419.160156 268.199219 414.289062 264.566406 410.617188 C 260.9375 406.929688 256.082031 404.917969 250.945312 404.878906 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#ff3131"
        d="M 614.496094 248.710938 C 614.496094 248.710938 650.214844 204.359375 651.304688 201.519531 C 656.007812 189.71875 652.921875 177.789062 644.53125 170.054688 C 635.195312 161.476562 621.515625 160.570312 611.261719 167.027344 C 609.210938 168.304688 567.8125 201.519531 567.8125 201.519531 C 531.644531 173.21875 488.496094 155.152344 442.976562 149.226562 L 437.367188 148.511719 L 442.714844 120.640625 C 461.625 117.421875 476.039062 100.996094 476.039062 81.179688 L 339.386719 81.179688 C 339.386719 102.5 356.058594 119.886719 377.078125 121.128906 L 382.308594 148.265625 L 376.703125 148.960938 C 317.953125 156.714844 264.644531 183.71875 224.089844 225.886719 C 214.34375 236.066406 213.761719 252.003906 222.792969 262.824219 C 233.3125 275.339844 252.394531 276.1875 263.738281 264.386719 C 299.53125 227.316406 348.851562 204.625 401.011719 202.347656 C 512.300781 197.453125 607.007812 284.296875 611.84375 395.789062 C 614.160156 449.757812 595.339844 501.46875 558.777344 541.25 C 522.292969 581.085938 472.445312 604.304688 418.417969 606.621094 C 376.191406 608.425781 335.304688 597.269531 300.492188 574.839844 C 295.917969 571.863281 290.6875 570.453125 285.398438 570.453125 C 279.472656 570.453125 273.46875 572.316406 268.386719 575.929688 C 252.523438 587.351562 252.261719 611.003906 268.050781 622.617188 C 274.183594 627.132812 279.867188 631.066406 284.4375 633.566406 C 323.503906 655.433594 367.746094 666.722656 413.847656 666.007812 C 482.566406 664.917969 546.792969 637.125 594.703125 587.726562 C 642.535156 538.351562 668.257812 473.28125 667.222656 404.558594 C 667.355469 348.390625 648.671875 293.140625 614.496094 248.710938 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#ff3131"
        d="M 437.3125 441.667969 C 439.625 439.917969 441.753906 437.980469 443.691406 435.851562 C 444.652344 434.835938 445.496094 433.800781 446.269531 432.710938 L 450.972656 425.21875 L 513.769531 324.828125 C 515.066406 322.835938 515.914062 320.632812 516.289062 318.449219 C 517.777344 311.9375 515.835938 304.976562 510.871094 300.066406 C 508.027344 297.222656 504.546875 295.4375 500.878906 294.722656 C 495.777344 293.761719 490.378906 294.722656 485.785156 297.75 L 389.78125 362.296875 C 385.28125 364.347656 381.21875 367.132812 377.605469 370.535156 L 377.472656 370.613281 C 370.003906 377.574219 364.769531 387.113281 363.226562 398.085938 C 359.613281 423.488281 377.417969 447.085938 402.875 450.625 C 415.125 452.375 427.375 449.210938 437.3125 441.667969 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g fill="#000000" fill-opacity="1">
        <g transform="translate(788.478468, 805.199979)">
          <g>
            <path d="M 10.734375 0.34375 C 9.390625 0.34375 8.203125 0.0703125 7.171875 -0.46875 C 6.148438 -1.019531 5.363281 -1.773438 4.8125 -2.734375 C 4.269531 -3.703125 4 -4.796875 4 -6.015625 C 4 -7.234375 4.269531 -8.320312 4.8125 -9.28125 C 5.363281 -10.25 6.148438 -11.015625 7.171875 -11.578125 C 8.203125 -12.140625 9.390625 -12.421875 10.734375 -12.421875 C 12.109375 -12.421875 13.304688 -12.144531 14.328125 -11.59375 C 15.359375 -11.050781 16.144531 -10.296875 16.6875 -9.328125 C 17.238281 -8.367188 17.515625 -7.28125 17.515625 -6.0625 C 17.515625 -4.207031 16.898438 -2.675781 15.671875 -1.46875 C 14.441406 -0.257812 12.796875 0.34375 10.734375 0.34375 Z M 10.734375 0.34375 " />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default StopWatch
